function getFormData(data){
  const formData = new FormData();
  for (const key in data) {
    if(Array.isArray(data[key]) && data[key].length > 0){
      for (const index in data[key]){
        for (const key2 in data[key][index]) {
          formData.append(`${key}[${index}].${key2}`, data[key][index][key2]);
        }
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
}

function sliceText(text, maxCharacter) {
  return text.slice(0, maxCharacter)
}

function searchValue(key, object) {
  for (let val in object) {
    const obj = object[val];
    if (obj.key === key)
      return obj;
  }
  return {}
}

export const formatNumber = function (val, fractionDigits = 2) {
  const d = parseFloat(val);
  if (!isNaN(d)) {
    return d.toLocaleString('en', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  } else {
    return '';
  }
};

export const getFileName = (response) => {
  const contentDisposition = response.headers['content-disposition'];
  return contentDisposition.substr(contentDisposition.indexOf('filename=') + 9);
};


export default {
  getFormData,
  sliceText,
  searchValue,
  formatNumber,
  getFileName
}

