<template>
  <b-button
      class="
      business-card
      d-block
      animate__animated animate__fadeIn
    "
      @click="selectBusiness"
  >
    <div class="h-100 d-flex flex-column justify-content-between">
      <section class="text-left">
        <span class="title text-dark">{{ business.name }}</span>
        <span class="business-card-subtitle d-block">{{ business.identifier }}</span>
      </section>

      <div class="w-100 d-flex justify-content-end">
        <img src="@/core/assets/images/businesses/business.svg" alt="Ícono de empresa"/>
      </div>
    </div>
  </b-button>
</template>

<script>

export default {
  name: "BusinessCard",

  data() {
    return {};
  },
  methods: {
    async selectBusiness(){
      // TODO Put loading here
      this.$store.commit('app/business', this.business);
      await this.$store.dispatch('app/loadAvailableDate');
      await this.$store.dispatch('app/loadStartDate');
      await this.$store.dispatch('app/loadLastClosedDate');
      this.$router.push({name: 'dashboard'})
    }
  },
  props: {
    business: Object,
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/card';
</style>
