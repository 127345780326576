var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar relación" : "Nueva relación")+" ")])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos de la relación" : "Datos de la nueva relación")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body"},[_c('form',{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"code"}},[_vm._v("Código *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.relationship.productId),expression:"relationship.productId",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                      'custom-form-control-error':
                        _vm.$v.relationship.productId.$error &&
                    !_vm.$v.relationship.productId.required
                    },attrs:{"id":"code","type":"text","maxlength":"15","autocomplete":"off","placeholder":"Ingresar código"},domProps:{"value":(_vm.relationship.productId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.relationship, "productId", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.relationship.productId.$error &&
                    !_vm.$v.relationship.productId.required
                  )?_c('form-error',{attrs:{"message":"Código es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descripción *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.relationship.productDesc),expression:"relationship.productDesc",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                      'custom-form-control-error':
                        _vm.$v.relationship.productDesc.$error &&
                    !_vm.$v.relationship.productDesc.required
                    },attrs:{"id":"description","type":"text","maxlength":"200","autocomplete":"off","placeholder":"Ingresar descripción"},domProps:{"value":(_vm.relationship.productDesc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.relationship, "productDesc", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.relationship.productDesc.$error &&
                    !_vm.$v.relationship.productDesc.required
                  )?_c('form-error',{attrs:{"message":"Descripción es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"code"}},[_vm._v("Código equivalente *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.relationship.productRelatedId),expression:"relationship.productRelatedId",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                      'custom-form-control-error':
                        _vm.$v.relationship.productRelatedId.$error &&
                    !_vm.$v.relationship.productRelatedId.required
                    },attrs:{"id":"relatedCode","type":"text","maxlength":"15","autocomplete":"off","placeholder":"Ingresar código equivalente"},domProps:{"value":(_vm.relationship.productRelatedId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.relationship, "productRelatedId", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.relationship.productRelatedId.$error &&
                    !_vm.$v.relationship.productRelatedId.required
                  )?_c('form-error',{attrs:{"message":"Código equivalente es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"conversionFactor"}},[_vm._v("Unidad de conversión *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.relationship.conversionFactor),expression:"relationship.conversionFactor",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                      'custom-form-control-error':
                        _vm.$v.relationship.conversionFactor.$error &&
                    !_vm.$v.relationship.conversionFactor.required
                    },attrs:{"id":"conversionFactor","type":"text","maxlength":"10","autocomplete":"off","placeholder":"Ingresar unidad de conversión"},domProps:{"value":(_vm.relationship.conversionFactor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.relationship, "conversionFactor", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.relationship.conversionFactor.$error &&
                    !_vm.$v.relationship.conversionFactor.required
                  )?_c('form-error',{attrs:{"message":"Unidad de conversión es requerida"}}):_vm._e(),(
                    _vm.$v.relationship.conversionFactor.$error &&
                    !_vm.$v.relationship.conversionFactor.decimal
                  )?_c('form-error',{attrs:{"message":"La unidad de conversión debe ser un valor numérico"}}):_vm._e(),(
                    _vm.$v.relationship.conversionFactor.$error &&
                    !_vm.$v.relationship.conversionFactor.greaterThan
                  )?_c('form-error',{attrs:{"message":"La unidad de conversión debe ser un valor mayor a 0"}}):_vm._e()],1),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-cancel mr-1",attrs:{"to":{ name: 'config-relation-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v("Guardar")])],1)])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }