<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Procesos diarios</h1>
    </div>

    <div class="table-container">
      <div
        class="
          table-header
          d-flex
          flex-column flex-md-row
          justify-content-between
          align-items-center
        "
      >

        <div class="d-flex align-items-center justify-content-between ml-2">

        </div>

        <section class="d-flex align-items-center">
          <RefreshTableButton text="Actualizar" class="mr-10p" @click="loadData"/>
          <SearchBar
            placeholder="Buscar por procesado por"
            @search="onSearch"
            class="mr-10p"
          />
          <ButtonAdd
            :route="{name:'daily-process-load'}"
            text="Procesar libro diario"
          />
          <ButtonAdd
            :route="{name:'daily-process-load-range'}"
            text="Procesar libro diario en rango"
          />
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table w-100">
          <thead>
          <tr>
            <th>Fecha del libro</th>
            <th>Valor hallado</th>
            <th>Valor final</th>
            <th>Diferencia Soles</th>
            <th>Procesado por</th>
            <th>Fecha y hora de procesamiento</th>
            <th>Estado</th>
            <th>Opciones</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="o in list" :key="o.id">

            <td class="text-center" v-local-date="o.date">{{ o.date }}</td>
            <td class="text-center" v-decimal:pen="o.foundValue"></td>
            <td class="text-center" v-decimal:pen="o.finalValue"></td>
            <td :class="{'text-center': true, 'text-error': o.diffValue < 0}" v-decimal:pen="o.diffValue"></td>
            <td class="text-center">{{ o.createdBy }}</td>
            <td class="text-center" v-local-date:hour="o.createdAt"></td>
            <td class="text-center"><span :class="`badge ${getStatus(o.status).color}`">{{
                (o.status === DailyProcessStatus.CLOSED.key && o.holiday) ? 'NO LABORABLE' :
                  getStatus(o.status).label
              }}</span>
            </td>

            <td class="text-center d_option pr-md-4">
              <Dropdown v-if="o.status !== DailyProcessStatus.RUNNING.key">
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Cerrar día"
                      @click="openCloseProcess(o)"
                      v-if="getOptions(o.status).includes('close')"
                    />
                    <DropdownItem
                      text="Eliminar"
                      @click="openDeleteProcess(o)"
                      v-if="getOptions(o.status).includes('delete')"
                    />
                    <DropdownItem
                      text="Descargar comparativo"
                      @click="downloadComparative(o.id)"
                      v-if="getOptions(o.status).includes('downloadComparative')"
                    />
                    <DropdownItem
                      text="Descargar libro detallado"
                      @click="downloadInventory(o.id)"
                      v-if="getOptions(o.status).includes('downloadInventory') && !o.holiday"
                    />
                  </template>
                </Dropdown>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
    <ConfirmationModal :show="confirmationModal.show"
                       @hide="closeConfirmationModal"
                       :data="confirmationModal"
                       @confirm="confirmModal"/>
  </div>

</template>

<script>
import {DownloadService, ProcessService} from "@/core/services";
import {Alert, Constants, Util} from '@/core/utils'
import {ConfirmationModalMixin, PaginationMixin} from '@/core/mixins'
import fileDownload from 'js-file-download';
import {ButtonAdd, ConfirmationModal, RefreshTableButton, SearchBar, Dropdown, DropdownItem} from "@/core/components";

export default {
  components: {
    ButtonAdd,
    SearchBar,
    RefreshTableButton,
    ConfirmationModal,
    Dropdown,
    DropdownItem
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      DailyProcessStatus: Constants.DailyProcessStatus,
      selectedOption: null,
    }
  },
  mixins: [PaginationMixin, ConfirmationModalMixin],
  methods: {
    getStatus(key) {
      return Util.searchValue(key, Constants.DailyProcessStatus)
    },
    getOptions(status) {
      let val = [];
      if (status === Constants.DailyProcessStatus.FINISHED.key)
        val = ['delete', 'close', 'downloadComparative', 'downloadInventory'];
      else if (status === Constants.DailyProcessStatus.CLOSED.key)
        val = ['downloadComparative', 'downloadInventory'];
      return val;
    },
    async loadData() {
      try {
        await this.$store.dispatch('app/loading', true);
        const params = {page: this.page - 1., size: this.pageSize, sort: 'date,desc', search: this.search};
        const response = await ProcessService.listDaily(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
    openDeleteProcess(o) {
      this.selectedOption = o
      this.openConfirmationModal({acceptText: "Borrar", action: "delete"});
    },
    openCloseProcess(o) {
      this.selectedOption = o
      this.openConfirmationModal({
        title: 'Cerrar proceso diario',
        description: "Una vez cerrado el día, no se podrá realizar ninguna otra acción",
        acceptText: "Aceptar",
        action: 'close',
      });
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === 'delete')
        this.deleteProcess();
      else if (this.confirmationModal.action === 'close')
        this.closeProcess();
    },
    async deleteProcess() {
      try {
        await this.$store.dispatch('app/loading', true);
        const resp = (await ProcessService.deleteDaily(this.selectedOption.id)).payload;

        const obj = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (obj) {
          const index = this.list.indexOf(obj);
          this.list.splice(index, 1);
        }
        Alert.success("Proceso diario eliminado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
    async closeProcess() {
      try {
        await this.$store.dispatch('app/loading', true);
        const resp = (await ProcessService.closeDaily(this.selectedOption.id)).payload;

        const process = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (process) {
          const index = this.list.indexOf(process);
          this.$set(this.list, index, resp);
        }
        await this.$store.dispatch('app/loadAvailableDate');
        await this.$store.dispatch('app/loadLastClosedDate');
        Alert.success("Proceso diario cerrado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
    async downloadComparative(processId) {
      try {
        await this.$store.dispatch('app/loading', true);
        const response = await DownloadService.dailyComparative({processId});
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
    async downloadInventory(processId) {
      try {
        await this.$store.dispatch('app/loading', true);
        const response = await DownloadService.dailyInventory({processId});
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    }
  },
  async mounted() {
    await this.loadData();
  }
}
</script>

<style lang="stylus" scoped>

</style>



