import DailyView from "../DailyView.vue";
import VoucherBatchList from "../Pages/VoucherBatchList.vue";
import VoucherBatchLoad from "../Pages/VoucherBatchLoad.vue";
import FinalBatchList from "../Pages/FinalBatchList.vue";
import FinalBatchLoad from "../Pages/FinalBatchLoad.vue";
import PurchaseList from "../Pages/PurchaseList.vue";
import ProcessList from "../Pages/ProcessList.vue";
import ProcessLoad from "../Pages/ProcessLoad.vue";
import ProcessLoadRange from "../Pages/ProcessLoadRange.vue";
import InventoryDownload from "../Pages/InventoryDownload.vue";
import ConversionSumList from "../Pages/ConversionSumList.vue";
import CustomVoucherList from "../Pages/CustomVoucherList.vue";
import CustomVoucherEditor from "../Pages/CustomVoucherEditor.vue";
import VoucherCostEditor from "../Pages/VoucherCostEditor.vue";
import VoucherList from "../Pages/VoucherList.vue";
import UpdateVoucherCost from "../Pages/UpdateVoucherCost.vue";
import ListVoucherCostDifference from "../Pages/ListVoucherCostDifference.vue";

const routes = [
  {
    path: '/daily',
    component: DailyView,
    children: [
      {
        path: 'voucher-batch',
        name: 'daily-voucher-batch-list',
        component: VoucherBatchList
      },
      {
        path: 'voucher-batch/load',
        name: 'daily-voucher-batch-load',
        component: VoucherBatchLoad
      },
      {
        path: 'final-batch',
        name: 'daily-final-batch-list',
        component: FinalBatchList
      },
      {
        path: 'final-batch/load',
        name: 'daily-final-batch-load',
        component: FinalBatchLoad
      },
      {
        path: 'validate-purchases-cost',
        name: 'daily-purchases-list',
        component: PurchaseList
      },
      {
        path: 'process',
        name: 'daily-process-list',
        component: ProcessList
      },
      {
        path: 'process/load',
        name: 'daily-process-load',
        component: ProcessLoad
      },
      {
        path: 'process/load/range',
        name: 'daily-process-load-range',
        component: ProcessLoadRange
      },
      {
        path: 'inventory-download',
        name: 'daily-inventory-download',
        component: InventoryDownload
      },
      {
        path: 'validate-conversion-sum',
        name: 'daily-conversion-sum-list',
        component: ConversionSumList
      },
      {
        path: 'custom-voucher',
        name: 'daily-custom-voucher-list',
        component: CustomVoucherList
      },
      {
        path: 'internal-voucher',
        name: 'daily-voucher-list',
        component: VoucherList
      },
      {
        path: 'voucher/cost/update',
        name: 'update-daily-voucher-cost',
        component: UpdateVoucherCost
      },
      {
        path: 'custom-voucher/create',
        name: 'daily-custom-voucher-create',
        component: CustomVoucherEditor
      },
      {
        path: 'custom-voucher/:voucherId',
        name: 'daily-custom-voucher-edit',
        component: CustomVoucherEditor
      },
      {
        path: 'voucher/:voucherId',
        name: 'daily-voucher-edit-cost',
        component: VoucherCostEditor
      },
      {
        path: 'voucher/cost/difference',
        name: 'list-daily-voucher-cost-difference',
        component: ListVoucherCostDifference
      },
    ]
  },
]

export default routes
