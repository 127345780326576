import { helpers } from 'vuelidate/lib/validators'
const MEGA_TO_BYTES_FACTOR = 1048576;

const maxFileSize = (numberMegaBytes) => (value) =>  {
  if (!value) {
    return true;
  }
  return (value.size < MEGA_TO_BYTES_FACTOR * numberMegaBytes);
};

const fileType = (typeFile) => (value) =>  {
  if (!value) {
    return true;
  }
  return (value.type === typeFile);
};

const greaterThan = (param) =>
  helpers.withParams(
    {type: 'greaterThan', value: param},
    (value) => !helpers.req(value) || value > param
  )

const url = (url) => /(https?:\/\/)(www\.)?(?!www)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&/=]*)/.test(url);

export default {
  maxFileSize,
  fileType,
  url,
  greaterThan
}

