import ConfigView from "../ConfigView.vue";
import SeriesList from "../Pages/SeriesList.vue";
import SerieEditor from "../Pages/SerieEditor.vue";
import ProductRelationshipList from "../Pages/ProductRelationshipList.vue";
import ProductRelationshipEditor from "../Pages/ProductRelationshipEditor.vue";
import ProductRelationshipLoad from "../Pages/ProductRelationshipLoad.vue";
import DataDeletion from "../Pages/DataDeletion.vue";

const routes = [
  {
    path: '/config',
    component: ConfigView,
    children: [
      {
        path: 'series',
        name: 'config-series-list',
        component: SeriesList
      },
      {
        path: 'series/create',
        name: 'config-serie-create',
        component: SerieEditor
      },
      {
        path: 'series/:serieId',
        name: 'config-serie-edit',
        component: SerieEditor
      },
      {
        path: 'relations',
        name: 'config-relation-list',
        component: ProductRelationshipList
      },
      {
        path: 'relations/create',
        name: 'config-relation-create',
        component: ProductRelationshipEditor
      },
      {
        path: 'relations/:id',
        name: 'config-relation-edit',
        component: ProductRelationshipEditor
      },
      {
        path: 'relations/load',
        name: 'config-relation-load',
        component: ProductRelationshipLoad
      },
      {
        path: 'data/deletion',
        name: 'config-data-deletion',
        component: DataDeletion
      },
    ]
  },
]

export default routes
