<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar relación" : "Nueva relación" }}
      </h1>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing
                    ? "Datos de la relación"
                    : "Datos de la nueva relación"
                }}
              </h3>
            </b-card-header>

            <b-card-body class="custom-card-body">
              <form class="custom-form" @submit.prevent="save">
                <div class="form-group">
                  <label for="code">Código *</label>
                  <input
                    id="code"
                    type="text"
                    maxlength="15"
                    v-model.trim="relationship.productId"
                    autocomplete="off"
                    placeholder="Ingresar código"
                    class="custom-form-control"
                    :class="{
                        'custom-form-control-error':
                          $v.relationship.productId.$error &&
                      !$v.relationship.productId.required
                      }"
                  />
                  <form-error
                    message="Código es requerido"
                    v-if="
                      $v.relationship.productId.$error &&
                      !$v.relationship.productId.required
                    "
                  ></form-error>
                </div>
                <div class="form-group">
                  <label for="description">Descripción *</label>
                  <input
                    id="description"
                    type="text"
                    maxlength="200"
                    v-model.trim="relationship.productDesc"
                    autocomplete="off"
                    placeholder="Ingresar descripción"
                    class="custom-form-control"
                    :class="{
                        'custom-form-control-error':
                          $v.relationship.productDesc.$error &&
                      !$v.relationship.productDesc.required
                      }"
                  />
                  <form-error
                    message="Descripción es requerida"
                    v-if="
                      $v.relationship.productDesc.$error &&
                      !$v.relationship.productDesc.required
                    "
                  ></form-error>
                </div>
                <div class="form-group">
                  <label for="code">Código equivalente *</label>
                  <input
                    id="relatedCode"
                    type="text"
                    maxlength="15"
                    v-model.trim="relationship.productRelatedId"
                    autocomplete="off"
                    placeholder="Ingresar código equivalente"
                    class="custom-form-control"
                    :class="{
                        'custom-form-control-error':
                          $v.relationship.productRelatedId.$error &&
                      !$v.relationship.productRelatedId.required
                      }"
                  />
                  <form-error
                    message="Código equivalente es requerido"
                    v-if="
                      $v.relationship.productRelatedId.$error &&
                      !$v.relationship.productRelatedId.required
                    "
                  ></form-error>
                </div>
                <div class="form-group">
                  <label for="conversionFactor">Unidad de conversión *</label>
                  <input
                    id="conversionFactor"
                    type="text"
                    maxlength="10"
                    v-model.trim="relationship.conversionFactor"
                    autocomplete="off"
                    placeholder="Ingresar unidad de conversión"
                    class="custom-form-control"
                    :class="{
                        'custom-form-control-error':
                          $v.relationship.conversionFactor.$error &&
                      !$v.relationship.conversionFactor.required
                      }"
                  />
                  <form-error
                    message="Unidad de conversión es requerida"
                    v-if="
                      $v.relationship.conversionFactor.$error &&
                      !$v.relationship.conversionFactor.required
                    "
                  ></form-error>

                  <form-error
                    message="La unidad de conversión debe ser un valor numérico"
                    v-if="
                      $v.relationship.conversionFactor.$error &&
                      !$v.relationship.conversionFactor.decimal
                    "
                  ></form-error>

                  <form-error
                    message="La unidad de conversión debe ser un valor mayor a 0"
                    v-if="
                      $v.relationship.conversionFactor.$error &&
                      !$v.relationship.conversionFactor.greaterThan
                    "
                  ></form-error>
                </div>
                <div class="form-group-buttons text-right">
                  <router-link
                    :to="{ name: 'config-relation-list' }"
                    class="button button-cancel mr-1"
                  >
                    {{ editing ? "Volver" : "Cancelar" }}
                  </router-link>
                  <button class="button button-primary" type="submit">Guardar</button>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ProductService} from "@/core/services";
import {Alert, CustomValidators} from "@/core/utils";
import {decimal, required} from "vuelidate/lib/validators";
import FormError from "@/core/components/form/FormError.vue";

export default {
  components: {
    FormError
  },
  data() {
    return {
      relationship: {
        productId: null,
        productDesc: null,
        productRelatedId: null,
        conversionFactor: null,
      },
      editing: false,
    };
  },
  validations: {
    relationship: {
      productId: {required},
      productDesc: {required},
      productRelatedId: {required},
      conversionFactor: {
        required,
        decimal,
        greaterThan: CustomValidators.greaterThan(0),
      },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      const data = {...this.relationship};

      try {
        let response;
        if (!this.editing)
          response = await ProductService.saveRelationship(data);
        else
          response = await ProductService.updateRelationship(
            this.$route.params.id,
            data
          );

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-relation-edit",
            params: {id: response.payload.id},
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await ProductService.getRelationship(id);
        this.relationship = {...response.payload};
        this.editing = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped></style>



