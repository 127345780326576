<template>
<div>
  <div :class="`wrapper-page${isMenuOpen ? '' : '-closeSidebar'}`">
      <page-sidebar/>
    <div class="wrapper-page__content">
      <page-header/>
      <div class="wrapper-page__inner">
        <b-container fluid>
          <slot></slot>
        </b-container>
      </div>
    </div>
    <page-footer/>
  </div>
  </div>
</template>

<script>
import PageHeader from "./PageHeader";
import PageSidebar from "./PageSidebar";
import PageFooter from "./PageFooter";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PageBase",
  components: { PageHeader, PageSidebar, PageFooter },
  data() {
    return {
      matchMediaMd: window.matchMedia("(max-width: 768px)"),
    }
  },
  computed:{
    ...mapGetters(
        {
          currentSubmenu: 'app/getCurrentSubmenu',
          isMenuOpen: 'app/isMenuOpen'
        },
    ),
  },
  methods: {
    isMatchMedia() {
    if (this.matchMediaMd.matches) {
      this.setMenu(false)
    }else {
      this.setMenu(true)
    }
  },
    ...mapMutations({
      setCurrentSubmenu: "app/setCurrentSubmenu",
      setMenu: "app/setMenu",
    }),

  },
  created () {
    this.matchMediaMd.addListener(this.isMatchMedia)
  }
}
</script>

<style scoped lang="stylus">
@import "../styles/pageBase.styl"

</style>
