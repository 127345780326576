<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargas diarias</h1>
    </div>

    <div class="table-container">
      <div
        class="
          table-header
          d-flex
          flex-column flex-md-row
          justify-content-between
          align-items-center
        "
      >

        <div class="d-flex align-items-center justify-content-between ml-2 ">

        </div>

        <section class="d-flex align-items-center">
          <SearchBar
            placeholder="Buscar por nombre de archivo o cargado por"
            class="mr-10p"
            @search="onSearch"
          />
          <ButtonAdd
            :route="{name:'daily-voucher-batch-load'}"
            text="Cargar"
            size="small"
          />
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table w-100">
          <thead>
          <tr>
            <th>
              Fecha
            </th>
            <th>Nombre de archivo</th>
            <th>Cant. de documentos</th>
            <th>Cant. de items</th>
            <th>Valor total de compras</th>
            <th>Cargado por</th>
            <th>Opciones</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="o in list" :key="o.id">
            <td class="text-center" v-local-date="o.date">{{ o.date }}</td>
            <td class="pl-5">{{ o.filename }}</td>
            <td class="text-center" v-int="o.vouchersCount"></td>
            <td class="text-center" v-int="o.itemsCount"></td>
            <td class="text-center" v-decimal:pen="o.purchasesValueSum"></td>
            <td class="text-center">{{ o.createdBy }}</td>

            <td class="text-center d_option pr-md-4">
              <Dropdown v-if="canDelete(o.date)">
                <template slot="dropdown-content">
                  <DropdownItem
                    text="Eliminar"
                    @click="openDelete(o)"
                  />
                </template>
              </Dropdown>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
    <ConfirmationModal :show="confirmationModal.show" @hide="closeConfirmationModal" :data="confirmationModal"
                       @confirm="deleteBatch"/>
  </div>

</template>

<script>
import {VoucherBatchService} from "@/core/services";
import {Alert} from '@/core/utils'
import {PaginationMixin, ConfirmationModalMixin} from '@/core/mixins'
import {ButtonAdd, SearchBar, ConfirmationModal, Dropdown, DropdownItem} from "@/core/components";
import * as moment from "moment";
import {mapGetters} from "vuex";

export default {
  components: {ButtonAdd, SearchBar, ConfirmationModal, Dropdown, DropdownItem},
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null
    }
  },
  mixins: [PaginationMixin, ConfirmationModalMixin],
  computed: {
    ...mapGetters(
      {
        lastClosedDate: 'app/getLastClosedDate'
      },
    ),
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch('app/loading', true);
        await this.$store.dispatch('app/loadLastClosedDate');
        const params = {page: this.page - 1., size: this.pageSize, sort: 'date,desc', search: this.search};

        const response = await VoucherBatchService.listDaily(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
    async deleteBatch() {
      this.closeConfirmationModal();
      try {
        await this.$store.dispatch('app/loading', true);
        const resp = (await VoucherBatchService.deleteDaily(this.selectedOption.id)).payload;

        const batch = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (batch) {
          const index = this.list.indexOf(batch);
          this.list.splice(index, 1);
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
    canDelete(batchDate) {
      return moment(this.lastClosedDate).diff(batchDate) < 0;
    },
    openDelete(o) {
      this.selectedOption = o
      this.openConfirmationModal({acceptText: "Borrar"});
    }
  },
  async mounted() {
    await this.loadData();
  }
}
</script>

<style lang="stylus" scoped>

</style>



