<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Guías internas</h1>
    </div>
    <div class="d-flex w-100">
      <div class="container-filter mr-4">
        <div class="container-header">
          <p class="header-title">Seleccione fecha</p>
        </div>
        <div class="container-body">
          <form @submit.prevent="loadData">
            <label for="input-date" class="body-label">Fecha</label>
            <b-input-group>
              <FormDatepicker label-id="input-date" :show-calendar="showDatePicker" :hideHeader="true"
                :defaultValue="selectedDate" :error="$v.selectedDate.$error" @show="showDatePicker = true"
                @hidden="showDatePicker = false" v-model="selectedDate" class="w-100 mx-2" />
            </b-input-group>
            <span class="error" v-if="$v.selectedDate.$error && !$v.selectedDate.required">Seleccione una fecha</span>
            <div class="d-flex flex-row-reverse pt-1">
              <button
                class="btn button-add text-white d-flex justify-content-center align-items-center btn-none small  mb-2"
                type="submit">Validar
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="table-container w-100">
        <div class="
          table-header
          d-flex
          flex-column flex-md-row
          justify-content-between
          align-items-center
        ">

          <div class="d-flex align-items-center justify-content-between ml-2 ">
            <div class="d-flex align-items-center justify-content-between ml-2 filter"
              @click="showFilters = !showFilters">
              <div class="icon-filter"></div>
              <div>Filtrar guías</div>
            </div>

          </div>
          <div class="d-flex flex-wrap align-items-center w-100  ml-2 ">
            <span v-for="(val, name) in filters" v-bind:key="name"
              class="d-flex justify-content-between ml-2 filter-secondary"> {{ getFilterName(name, val) }} <div
                class="icon-delete ml-3" @click="deleteFilter(name)"></div></span>
          </div>
          <filter-menu :show="showFilters" @hide="showFilters = false" :options="filterOptions"
            :selectedOptionFilter="filters" @setFilters="setFilters"></filter-menu>

          <section class="d-flex align-items-center">
            <SearchBar placeholder="Buscar por producto, descripción, serie, número, creado por" @search="onSearch"
              class="mr-10p" />
          </section>
        </div>
        <div class="mb-0">
          <table class="custom-table w-100">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Serie</th>
                <th>Número</th>
                <th>Producto</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Unidad</th>
                <th>Costo</th>
                <th>Actualizado por</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="o in list" :key="o.id">
                <td class="text-center" v-local-date="o.date"></td>
                <td class="text-center">{{ o.serie }}</td>
                <td class="text-center">{{ o.number }}</td>
                <td class="text-center">{{ o.productId }}</td>
                <td>{{ o.productDesc }}</td>
                <td class="text-center" v-decimal="o.quantity"></td>
                <td class="text-center">{{ o.unit }}</td>
                <td class="text-center" v-decimal:pen="o.cost"></td>
                <td class="text-center">{{ o.updatedBy }}</td>
                <td class="text-center d_option pr-md-4">
                  <Dropdown>
                    <template slot="dropdown-content">
                      <DropdownItem text="Editar costo"
                        @click="$router.push({ name: 'daily-voucher-edit-cost', params: { voucherId: o.id } })" />
                    </template>
                  </Dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-footer d-flex justify-content-end align-items-center">
          <pagination :total="total" :page="page" :page-size="pageSize" :pages="pages" :changePage="changePage"
            :changePageSize="changePageSize" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dropdown, DropdownItem, FilterMenu, FormDatepicker, SearchBar } from "@/core/components";
import { ConfirmationModalMixin, FilterMixin, PaginationMixin } from '@/core/mixins';
import { VoucherService } from "@/core/services";
import { Alert, Constants } from '@/core/utils';
import { mapGetters } from "vuex";

export default {
  components: {
    SearchBar,
    FilterMenu,
    Dropdown,
    DropdownItem,
    FormDatepicker
  },
  data() {
    return {
      list: [],
      selectedDate: null,
      showDatePicker: false,
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Costo cero",
          key: "costZero",
          type: Constants.TypeFilters.SINGLE,
          options: [{ key: 1, label: 'SÍ' }, { key: 0, label: 'NO' }],
        },
        {
          label: "Series",
          key: "series",
          type: Constants.TypeFilters.MULTIPLE,
          options: [{ key: 'G15', label: 'G15' }, { key: 'G65', label: 'G65' }],
        }],
    }
  },
  validations: {
    selectedDate: {},
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  computed: {
    ...mapGetters(
      {
        lastClosedDate: 'app/getLastClosedDate'
      },
    ),
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch('app/loading', true);
        const params = {
          page: this.page - 1., size: this.pageSize, sort: 'date,desc', search: this.search, custom: false,
          voucherTypeCodes: ['X', 'M'], ...this.filters,
          date: this.selectedDate
        };
        const response = await VoucherService.listDaily(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
  },
  async mounted() {
    await this.loadData();
  }
}
</script>

<style lang="stylus" scoped>

</style>



