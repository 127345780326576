<template>
  <router-link active-class="active"
               :to="{ name: routeName}"
               @mouseover.native="tooltipType = routeName"
               @mouseleave.native="tooltipType = null"
               class="sidebar-subitems d-flex align-items-center text-decoration-none">
    <div class="bullet-icon"></div>
    <span class="sidebar-subitems-text">{{ text }}</span>
    <CustomTooltip custom-class="tooltip-sidebar" v-if="!isMenuOpen && tooltipType === routeName"
                   :text="text"/>
  </router-link>
</template>

<script>
import CustomTooltip from "../CustomToolTip"
import {mapGetters} from "vuex";

export default {
  components: {CustomTooltip},
  props: {
    routeName: String,
    text: String,
  },
  data(){
    return {
      tooltipType: null
    }
  },
  methods: {

  },
  computed: {
    ...mapGetters({
      isMenuOpen: "app/isMenuOpen",
    }),
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/components/sub-item"
</style>
