export default {
  data: function () {
    return {
      filters: {},
      showFilters: false,

    }
  },
  methods: {
    setFilters(options) {
      this.showFilters = false
      this.filters = {
        ...options
      }
      this.loadData();
    },
    getFilterName(label, key) {
      if (Array.isArray(key)) {
        const names = key.map((val) => {
          const typeName = this.filterOptions.find(e => e.key === label)
          const typeoptions = typeName.options.find(e => e.key === val)

          return typeoptions.label;
        });
        const typeName = this.filterOptions.find(e => e.key === label)
        return `${typeName.label} = ${names.join(', ')}`
      } else if (typeof key === 'object') {
        const typeName = this.filterOptions.find(e => e.key === label)
        const textDate = `${key.startDate} hasta ${key.endDate}`

        return `${typeName.label} = ${key.startDate === key.endDate ? key.startDate : textDate}`
      } else {
        const typeName = this.filterOptions.find(e => e.key === label)
        const typeoptions = typeName.options.find(e => e.key === key)
        return `${typeName.label} = ${typeoptions.label}`
      }

    },
    deleteFilter(label) {
      delete this.filters[label]
      this.filters = {...this.filters};
      this.loadData();
    },
  }
}
