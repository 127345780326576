import {http} from '@/http'

export default {
  list(params) {
    return http.get('api/v1/voucherserie', {params})
  },
  get(serieId) {
    return http.get(`api/v1/voucherserie/${serieId}`)
  },
  getActive(params) {
    return http.get('api/v1/voucherserie/active', {params})
  },
  save(payload) {
    return http.post('api/v1/voucherserie', payload)
  },
  update(serieId, payload) {
    return http.put(`api/v1/voucherserie/${serieId}`, payload)
  },
  toggleStatus(id) {
    return http.patch(`api/v1/voucherserie/${id}`)
  },
  delete(id) {
    return http.delete(`api/v1/voucherserie/${id}`)
  },
}
