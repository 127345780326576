<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Relación entre productos</h1>
    </div>
    <div class="table-container">
      <div
        class="
          table-header
          d-flex
          flex-column flex-md-row
          justify-content-end
          align-items-center
        "
      >
        <section class="d-flex align-items-center">
          <DownloadTableData
            text="Descargar"
            class="mr-10p"
            @click="download"
          />
          <SearchBar
            placeholder="Buscar por código, descripción o código equivalente"
            @search="onSearch"
            class="mr-10p"
          />
          <ButtonAdd
            :route="{ name: 'config-relation-load' }"
            text="Cargar"
            size="small"
          />
          <ButtonAdd
            :route="{ name: 'config-relation-create' }"
            text="Agregar relación"
          />
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>Código</th>
              <th>Descripción</th>
              <th>Código equivalente</th>
              <th>Unidad de conversión</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">{{ o.productId }}</td>
              <td class="pl-5">{{ o.productDesc }}</td>
              <td class="text-center">{{ o.productRelatedId }}</td>
              <td class="text-center">{{ o.conversionFactor }}</td>

              <td class="text-center d_option pr-md-4">
                <custom-tooltip
                  text="Ver más opciones"
                  v-show="tooltipType === o.id"
                  custom-class="relation-ship"
                />
                <Dropdown>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Editar"
                      @click="$router.push({name: 'config-relation-edit', params: {id: o.id}})"
                    />
                    <DropdownItem
                      text="Eliminar"
                      @click="openDelete(o)"
                    />
                  </template>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="deleteRelationship"
    />
  </div>
</template>

<script>
import { DownloadService, ProductService } from "@/core/services";
import { Alert, Util } from "@/core/utils";
import { PaginationMixin, ConfirmationModalMixin } from "@/core/mixins";
import fileDownload from "js-file-download";
import {
  ButtonAdd,
  ConfirmationModal,
  CustomTooltip,
  DownloadTableData,
  SearchBar,
  Dropdown,
  DropdownItem,
} from "@/core/components";

export default {
  components: {
    ButtonAdd,
    SearchBar,
    DownloadTableData,
    CustomTooltip,
    ConfirmationModal,
    Dropdown,
    DropdownItem,
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
    };
  },
  mixins: [PaginationMixin, ConfirmationModalMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "productId",
          search: this.search,
        };
        const response = await ProductService.listRelationships(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async deleteRelationship() {
      this.closeConfirmationModal();
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (
          await ProductService.deleteRelationship(this.selectedOption.id)
        ).payload;

        const rel = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (rel) {
          const index = this.list.indexOf(rel);
          this.list.splice(index, 1);
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async download() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await DownloadService.productRelationship();
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar" });
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>



