import Pagination from '../components/Pagination.vue'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      pageSize: 20,
      pages: 1,
      page: 1,
      total: 0,
      search: null,
      filters: []
    }
  },
  computed: {
    totalPages: function () {
      return Math.ceil(this.total / this.pageSize);
    }
  },
  methods: {
    changePage(p) {
      this.page = p;
      this.loadData()
    },
    changePageSize(pageSize) {
      this.page = 1;
      this.pageSize = pageSize;
      this.loadData()
    },
    onSearch(keyword) {
      this.page = 1;
      this.search = keyword;
      this.loadData();
    },
    setFilters(filters) {
      this.filters = filters
      this.loadData();
    }
  }
};
