import {http} from '@/http'

export default {
  startProcess(params) {
    return http.post('api/v1/process/daily', null, {params})
  },
  listDaily(params) {
    return http.get('api/v1/process/daily', {params})
  },
  closeDaily(dailyId) {
    return http.put(`api/v1/process/daily/${dailyId}`)
  },
  deleteDaily(dailyId) {
    return http.delete(`api/v1/process/daily/${dailyId}`)
  },
  startProcessRange(params) {
    return http.post('api/v1/process/daily/range', null, {params})
  },
}
