<template>
  <section class="dropdown position-relative mx-auto">
    <button
      class="dropdown-button position-relative bg-transparent border-0"
      @mouseover="showTooltip = true"
      @mouseleave="showTooltip = false"
      @focus="focus = true"
      @blur="close()"
      @click="showTooltip = false"
    >
      <CustomTooltip
        text="Ver más opciones"
        v-show="showTooltip"
        custom-class="table-tooltip"
      />
      <img
        @mouseover="showTooltip = true"
        src="@/core/assets/images/components/options.svg"
        alt="Opciones"
        class="options-icon"
      />
    </button>

    <section
      class="dropdown-content"
      :class="{ 'dropdown-content-focus': focus }"
    >
      <slot name="dropdown-content"> </slot>
    </section>
  </section>
</template>

<script>
import CustomTooltip from "../CustomToolTip";

export default {
  components: {
    CustomTooltip,
  },
  data() {
    return {
      showTooltip: false,
      focus: false,
    };
  },
  methods: {
    close() {
      setTimeout(() => {
        this.focus = false;
      }, 200);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/core/styles/components/dropdown.styl';
</style>