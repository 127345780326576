<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Listar diferencia de costos entre guías G15 y G65</h1>
    </div>

    <div class="d-flex w-100">
      <div class="container-filter mr-4">
        <div class="container-header">
          <p class="header-title">Seleccione un rango de fechas</p>
        </div>
        <div class="container-body">
          <form @submit.prevent="loadData">
            <label for="start-date" class="body-label">Fecha inicial</label>
            <b-input-group>
              <FormDatepicker label-id="start-date" :show-calendar="isOpenStartPicker"
                :min="$store.getters['app/getStartDate']" :max="$store.getters['app/getAvailableDate']" :hideHeader="true"
                :defaultValue="startDate" :error="$v.startDate.$error" @show="isOpenStartPicker = true"
                @hidden="isOpenStartPicker = false" v-model="startDate" class="w-100 mx-2" />
            </b-input-group>
            <span class="error" v-if="$v.startDate.$error && !$v.startDate.required">Fecha inicial es
              requerida</span>
            <label for="end-date" class="body-label">Fecha inicial</label>
            <b-input-group>
              <FormDatepicker label-id="end-date" :show-calendar="isOpenEndPicker"
                :min="$store.getters['app/getStartDate']" :max="$store.getters['app/getAvailableDate']" :hideHeader="true"
                @show="isOpenEndPicker = true" @hidden="isOpenEndPicker = false" v-model="endDate" class="w-100 mx-2" />
            </b-input-group>

            <span class="error" v-if="$v.endDate.$error && !$v.endDate.required">Fecha final es
              requerida</span>
            <div class="d-flex flex-row-reverse pt-1">
              <button
                class="btn button-add text-white d-flex justify-content-center align-items-center btn-none small mb-2"
                type="submit">Consultar
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="table-container w-100">
        <div class="table-header
                            d-flex
                            flex-column flex-md-row
                            justify-content-between
                            align-items-center">

          <div class="d-flex align-items-center justify-content-between ml-2">

          </div>
          <div class="d-flex align-items-center justify-content-between ml-2">

          </div>
          <section class="d-flex align-items-center">

          </section>
        </div>
        <div class="mb-0">
          <table class="custom-table w-100">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Suma G15</th>
                <th>Suma G65</th>
                <th>Diferencia</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="o in list" :key="o.id">
                <td class="text-center" v-local-date="o.date">{{ o.date }}</td>
                <td class="text-center" v-decimal:pen="o.g15value"></td>
                <td class="text-center" v-decimal:pen="o.g65value"></td>
                <td class="text-center" v-decimal:pen="o.difference"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-footer d-flex justify-content-end align-items-center">
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { FormDatepicker } from "@/core/components";
import { VoucherService } from "@/core/services";
import { Alert } from '@/core/utils';
import { required } from "vuelidate/lib/validators";

export default {
  components: { FormDatepicker },
  data() {
    return {
      list: [],
      startDate: this.$store.getters["app/getAvailableDate"],
      endDate: this.$store.getters["app/getAvailableDate"],
      isOpenStartPicker: false,
      isOpenEndPicker: false,
    }
  },
  validations: {
    startDate: { required },
    endDate: { required },
  },
  methods: {
    async loadData() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      try {
        await this.$store.dispatch('app/loading', true);
        const params = { startDate: this.startDate, endDate: this.endDate };
        if (this.keyword)
          params.keyword = this.keyword;
        const response = await VoucherService.lisDailyVoucherCostDifference(params);
        this.list = response.payload;
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>



