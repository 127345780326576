import {http} from '@/http'

export default {
  dailyPurchases(params) {
    return http.get('api/v1/download/purchase/daily', {params, responseType: 'blob'})
  },
  dailyComparative(params) {
    return http.get('api/v1/download/comparative/daily', {params, responseType: 'blob'})
  },
  dailyInventory(params) {
    return http.get('api/v1/download/inventory/daily', {params, responseType: 'blob'})
  },
  dailyInventoryFiles(params) {
    return http.get('api/v1/download/inventory/daily/files', {params, responseType: 'blob'})
  },
  productRelationship() {
    return http.get('api/v1/download/product/relationship', {responseType: 'blob'})
  },
  productRelationshipTemplate() {
    return http.get('api/v1/download/product/relationship/template', {responseType: 'blob'})
  },
  dailyFinalBatchTemplate() {
    return http.get('api/v1/download/final/batch/template/daily', {responseType: 'blob'})
  },
  dailyConversionSum(params){
    return http.get('api/v1/download/conversion/sum/daily', {params, responseType: 'blob'})
  },
  dailyCustomVouchers(params){
    return http.get('api/v1/download/daily/custom/voucher', {params, responseType: 'blob'})
  }
}
