<template>
  <b-button :to="route" variant="none" :class="`btn button-add text-white d-flex justify-content-center align-items-center btn-none ${size}`">{{ text }}</b-button>
</template>

<script>
export default {
    props: {
        route: Object,
        text: String,
        size: {
          type: String,
          default: ''
          }
    }
}
</script>

<style lang="stylus" scoped>
@import '../../styles/components/button-add'
</style>