import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginRoutes from '@/modules/Login/Routes'
import DashboardRoutes from '@/modules/Dashboard/Routes'
import ConfigRoutes from '@/modules/Config/Routes'
import DailyRoutes from '@/modules/Daily/Routes'
import BusinessesRoutes from '@/modules/Businesses/Routes'
import store from '../store'

Vue.use(VueRouter);

let routes = [];

routes = routes.concat(LoginRoutes, DashboardRoutes, ConfigRoutes, DailyRoutes, BusinessesRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.excludeAuth) {
    if (!store.getters['auth/isAuthenticated']) {
      next();
    } else {
      await store.dispatch('app/loadStartupData');
      next({name: 'businesses'})
    }

  } else {
    if (store.getters['auth/isAuthenticated']) {
      await store.dispatch('app/loadStartupData');
      next()
    } else {
      next({name: 'login'});
    }
  }
});

export default router
