<template>
  <a href="" class="dropdown-item bg-white" @click.prevent="action()">
      {{ text }}
  </a>
</template>

<script>
export default {
    props: {
        text: String,
        to: {
            type: Object,
            default: null
        }
    },
    methods: {
        action() {
            if(this.to) {
                this.$router.push(this.to)
            } else {
                this.$emit('click')
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/styles/components/dropdown.styl';
</style>