export default {
  data: function () {
    return {
      confirmationModal: {
        show: false,
        title: null,
        description: null,
        acceptText: null,
        cancelText: null,
        action: null
      },
    }
  },
  methods: {
    openConfirmationModal({
                            title = "Borrar datos",
                            description = "Todos los datos seleccionados se borrarán de manera permanente",
                            acceptText = 'Aceptar',
                            cancelText = 'Cancelar',
                            action
                          }) {
      this.confirmationModal = {
        show: true, title, description, acceptText, cancelText, action
      }
    },
    closeConfirmationModal() {
      this.confirmationModal.show = false;
      this.confirmationModal.title = null;
      this.confirmationModal.description = null;
      this.confirmationModal.acceptText = null;
      this.confirmationModal.cancelText = null;
    }
  }
}
