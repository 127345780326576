import DashboardView from "../DashboardView.vue";
import DashboardPage from "../Pages/DashboardPage.vue";

const routes = [
  {
    path: '/',
    component: DashboardView,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardPage,
      },
    ]
  }
]

export default routes
