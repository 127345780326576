var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar guía externa" : "Nueva guía externa")+" ")])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos de la guía externa" : "Datos de la nueva guía externa")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Tipo de comprobante *")]),_c('FormSelect',{attrs:{"id":"type","items":_vm.voucherTypes,"defaultOption":"Elegir tipo de comprobante","showError":_vm.$v.voucher.voucherTypeId.$error},model:{value:(_vm.voucher.voucherTypeId),callback:function ($$v) {_vm.$set(_vm.voucher, "voucherTypeId", $$v)},expression:"voucher.voucherTypeId"}}),(
                    _vm.$v.voucher.voucherTypeId.$error &&
                    !_vm.$v.voucher.voucherTypeId.required
                  )?_c('form-error',{attrs:{"message":"Tipo de comprobante es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"serie"}},[_vm._v("Serie *")]),_c('FormSelect',{attrs:{"id":"serie","items":_vm.voucherSeries,"defaultOption":"Elegir serie","showError":_vm.$v.voucher.serie.$error},model:{value:(_vm.voucher.serie),callback:function ($$v) {_vm.$set(_vm.voucher, "serie", $$v)},expression:"voucher.serie"}}),(_vm.$v.voucher.serie.$error && !_vm.$v.voucher.serie.required)?_c('form-error',{attrs:{"message":"Serie es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group d-flex flex-column"},[_c('label',{attrs:{"for":"input-date"}},[_vm._v("Fecha *")]),_c('FormDatepicker',{staticClass:"w-100 datepicker-container--form-input",attrs:{"label-id":"input-date","show-calendar":_vm.showDatePicker,"hideHeader":false,"error":_vm.$v.voucher.date.$error},on:{"show":function($event){_vm.showDatePicker = true},"hidden":function($event){_vm.showDatePicker = false}},model:{value:(_vm.voucher.date),callback:function ($$v) {_vm.$set(_vm.voucher, "date", $$v)},expression:"voucher.date"}}),(_vm.$v.voucher.date.$error && !_vm.$v.voucher.date.required)?_c('form-error',{attrs:{"message":"Fecha es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"number"}},[_vm._v("Número de comprobante *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.voucher.number),expression:"voucher.number",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.voucher.number.$error && !_vm.$v.voucher.number.required,
                  },attrs:{"id":"number","type":"text","maxlength":"200","autocomplete":"off","placeholder":"Ingresar número de comprobante"},domProps:{"value":(_vm.voucher.number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.voucher, "number", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.voucher.number.$error && !_vm.$v.voucher.number.required
                  )?_c('form-error',{attrs:{"message":"Número de comprobante es requerido"}}):_vm._e(),(
                    _vm.$v.voucher.number.$error && !_vm.$v.voucher.number.numeric
                  )?_c('form-error',{attrs:{"message":"El número de comprobante debe ser un valor numérico"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"document"}},[_vm._v("Documento (GC, GM) *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.voucher.document),expression:"voucher.document",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.voucher.document.$error &&
                      !_vm.$v.voucher.document.required,
                  },attrs:{"id":"document","type":"text","maxlength":"200","autocomplete":"off","placeholder":"Ingresar documento"},domProps:{"value":(_vm.voucher.document)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.voucher, "document", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.voucher.document.$error &&
                    !_vm.$v.voucher.document.required
                  )?_c('form-error',{attrs:{"message":"Documento es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"productId"}},[_vm._v("Código de producto *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.voucher.productId),expression:"voucher.productId",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.voucher.productId.$error &&
                      !_vm.$v.voucher.productId.required,
                  },attrs:{"id":"productId","type":"text","maxlength":"20","autocomplete":"off","placeholder":"Ingresar código de producto"},domProps:{"value":(_vm.voucher.productId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.voucher, "productId", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.voucher.productId.$error &&
                    !_vm.$v.voucher.productId.required
                  )?_c('form-error',{attrs:{"message":"Código de producto es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descripción *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.voucher.productDesc),expression:"voucher.productDesc",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.voucher.productDesc.$error &&
                      !_vm.$v.voucher.productDesc.required,
                  },attrs:{"id":"description","type":"text","maxlength":"200","autocomplete":"off","placeholder":"Ingresar descripción"},domProps:{"value":(_vm.voucher.productDesc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.voucher, "productDesc", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.voucher.productDesc.$error &&
                    !_vm.$v.voucher.productDesc.required
                  )?_c('form-error',{attrs:{"message":"Descripción es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Unidad (UND, KG) *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.voucher.unit),expression:"voucher.unit",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.voucher.unit.$error && !_vm.$v.voucher.unit.required,
                  },attrs:{"id":"unit","type":"text","maxlength":"10","autocomplete":"off","placeholder":"Ingresar unidad"},domProps:{"value":(_vm.voucher.unit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.voucher, "unit", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.voucher.unit.$error && !_vm.$v.voucher.unit.required
                  )?_c('form-error',{attrs:{"message":"Unidad es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"quantity"}},[_vm._v("Cantidad *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.voucher.quantity),expression:"voucher.quantity",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.voucher.quantity.$error &&
                      !_vm.$v.voucher.quantity.required,
                  },attrs:{"id":"quantity","type":"text","maxlength":"20","autocomplete":"off","placeholder":"Ingresar cantidad"},domProps:{"value":(_vm.voucher.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.voucher, "quantity", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.voucher.quantity.$error &&
                    !_vm.$v.voucher.quantity.required
                  )?_c('form-error',{attrs:{"message":"Cantidad es requerida"}}):_vm._e(),(
                    _vm.$v.voucher.quantity.$error && !_vm.$v.voucher.quantity.decimal
                  )?_c('form-error',{attrs:{"message":"La cantidad debe ser un valor numérico"}}):_vm._e(),(
                    _vm.$v.$error && !_vm.$v.voucher.quantity.greaterThan
                  )?_c('form-error',{attrs:{"message":"La cantidad debe ser un valor mayor a 0"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"cost"}},[_vm._v("Costo *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.voucher.cost),expression:"voucher.cost",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      (_vm.$v.voucher.cost.$error && !_vm.$v.voucher.cost.required) ||
                      (_vm.$v.voucher.cost.$error && !_vm.$v.voucher.cost.decimal) ||
                      (_vm.$v.voucher.cost.$error && !_vm.$v.voucher.cost.minValue),
                  },attrs:{"id":"cost","type":"text","maxlength":"20","autocomplete":"off","placeholder":"Ingresar costo"},domProps:{"value":(_vm.voucher.cost)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.voucher, "cost", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.voucher.cost.$error && !_vm.$v.voucher.cost.required
                  )?_c('form-error',{attrs:{"message":"Costo es requerido"}}):_vm._e(),(
                    _vm.$v.voucher.cost.$error && !_vm.$v.voucher.cost.decimal
                  )?_c('form-error',{attrs:{"message":"El costo debe ser un valor numérico"}}):_vm._e(),(
                    _vm.$v.voucher.cost.$error && !_vm.$v.voucher.cost.minValue
                  )?_c('form-error',{attrs:{"message":"El costo no puede ser un valor menor a 0"}}):_vm._e()],1),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-cancel mr-1",attrs:{"to":{ name: 'daily-custom-voucher-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }