import { render, staticRenderFns } from "./CustomVoucherList.vue?vue&type=template&id=16a83ced&scoped=true&"
import script from "./CustomVoucherList.vue?vue&type=script&lang=js&"
export * from "./CustomVoucherList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a83ced",
  null
  
)

export default component.exports