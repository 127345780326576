<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">{{ editing ? 'Editar serie' : 'Nueva serie' }}</h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{ editing ? "Datos de la serie" : "Datos de la nueva serie" }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-group">
                  <label for="type">Tipo de comprobante *</label>
                  <FormSelect
                    id="voucher"
                    v-model="serie.voucherTypeId"
                    :items="voucherTypes"
                    defaultOption="Elegir tipo de comprobante"
                    :showError="$v.serie.voucherTypeId.$error"
                  />
                  <span
                    class="error"
                    v-if="
                      $v.serie.voucherTypeId.$error &&
                      !$v.serie.voucherTypeId.required
                    "
                  >
                    <img
                      src="@/core/assets/images/components/error-form.svg"
                      alt="Seleccionar"
                      class="error-icon"
                    />
                    Tipo de comprobante es requerido
                  </span>
                </div>
                <div class="form-group">
                  <label for="code">Código *</label>
                  <input
                    id="code"
                    type="text"
                    maxlength="5"
                    v-model.trim="serie.code"
                    autocomplete="off"
                    placeholder="Ingresar código"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error':
                        $v.serie.code.$error && !$v.serie.code.required,
                    }"
                  />
                  <span
                    class="error"
                    v-if="$v.serie.code.$error && !$v.serie.code.required"
                  >
                    <img
                      src="@/core/assets/images/components/error-form.svg"
                      alt="Seleccionar"
                      class="error-icon"
                    />
                    Código es requerido
                  </span>
                </div>
                <div class="form-group">
                  <label for="description">Descripción *</label>
                  <input
                    id="description"
                    type="text"
                    maxlength="200"
                    v-model.trim="serie.description"
                    autocomplete="off"
                    placeholder="Ingresar descripción"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error':
                        $v.serie.description.$error &&
                        !$v.serie.description.required,
                    }"
                  />
                  <span
                    class="error"
                    v-if="
                      $v.serie.description.$error &&
                      !$v.serie.description.required
                    "
                  >
                    <img
                      src="@/core/assets/images/components/error-form.svg"
                      alt="Seleccionar"
                      class="error-icon"
                    />
                    Descripción es requerida
                  </span>
                </div>
                <div class="form-group">
                  <label for="type">Tipo de movimiento *</label>
                  <FormSelect
                    id="type"
                    v-model="serie.type"
                    :items="serieTypes"
                    defaultOption="Elegir tipo de movimiento"
                    :showError="$v.serie.type.$error"
                  />
                  <span
                    class="error"
                    v-if="$v.serie.type.$error && !$v.serie.type.required"
                  >
                    <img
                      src="@/core/assets/images/components/error-form.svg"
                      alt="Seleccionar"
                      class="error-icon"
                    />
                    Tipo de movimiento es requerido
                  </span>
                </div>
                <div class="form-group-buttons text-right">
                  <router-link
                    :to="{ name: 'config-series-list' }"
                    class="button button-cancel mr-1"
                  >
                    {{ editing ? "Volver" : "Cancelar" }}
                  </router-link>
                  <button class="button button-primary" type="submit">
                    Guardar
                  </button>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VoucherSerieService, VoucherTypeService} from "@/core/services";
import {Alert, Constants} from "@/core/utils";
import {required} from "vuelidate/lib/validators";
import { FormSelect } from '@/core/components'

export default {
  data() {
    return {
      serie: {
        voucherTypeId: null,
        code: null,
        description: null,
        type: null,
      },
      serieTypes: [
        Constants.VoucherSerieType.INCOME,
        Constants.VoucherSerieType.OUTCOME,
      ],
      voucherTypes: [],
      editing: false,
    };
  },
  components: {
    FormSelect
  },
  validations: {
    serie: {
      voucherTypeId: {required},
      code: {required},
      description: {required},
      type: {required},
    },
  },
  watch: {
    "$route.params.serieId": function () {
      if (this.$route.params.serieId) this.load(this.$route.params.serieId);
    },
  },
  computed: {},
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      const data = {...this.serie};

      try {
        let response;
        if (!this.editing) response = await VoucherSerieService.save(data);
        else
          response = await VoucherSerieService.update(
            this.$route.params.serieId,
            data
          );

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-serie-edit",
            params: {serieId: response.payload.id},
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      }
    },
    async load(serieId) {
      try {
        this.$store.dispatch('app/loading', true);
        const response = await VoucherSerieService.get(serieId);
        this.serie = {...response.payload};
        this.serie.voucherTypeId = response.payload.voucherType.id;
        this.editing = true;
      } catch (e) {
        console.error(e)
      } finally {
        this.$store.dispatch('app/loading', false);
      }
    },
  },
  async created() {
    try{
      this.$store.dispatch('app/loading', true);
      const resp = await VoucherTypeService.listActive();
      this.voucherTypes = [];
      resp.payload.forEach((it) => {
        this.voucherTypes.push({key: it.id, label: it.name});
      });
    }catch (e){
      console.error(e);
    }finally {
      this.$store.dispatch('app/loading', false);
    }
    if (this.$route.params.serieId) this.load(this.$route.params.serieId);

  },
};
</script>

<style lang="stylus" scoped></style>



